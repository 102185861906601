import React, { Component } from 'react';
import Fade from 'react-reveal';
import { AiFillCloud, AiFillCode, AiFillDatabase } from 'react-icons/ai';
import { HiAcademicCap, HiSparkles } from 'react-icons/hi';
import { HiMapPin } from 'react-icons/hi2';
import { SiAzuredevops } from 'react-icons/si';

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = 'images/' + this.props.data.image;
    const bio = this.props.data.bio;
    const skills = this.props.data.skills;
    const location = this.props.data.location;
    const study = this.props.data.study;
    const interests = this.props.data.interests;
    const resumeDownload = this.props.data.resumedownload;

    const aboutItemsList = [
      { label: 'Location', text: location, icon: <HiMapPin /> },
      { label: 'Study', text: study, icon: <HiAcademicCap /> },
      { label: 'Interests', text: interests, icon: <HiSparkles /> },
    ];

    const aboutItems = aboutItemsList.map(({ label, text, icon }) => {
      return (
        <li key={label}>
          {icon}
          <span className='label'> {label}: </span>
          <span>{text}</span>
        </li>
      );
    });

    const skillItems = skills.map((skill) => {
      // Remove skill icons for now
      // let skillIcon = null;
      // if (skill.name.toLowerCase() === 'languages') skillIcon = <AiFillCode />;
      // else if (skill.name.toLowerCase() === 'cloud computing')
      //   skillIcon = <AiFillCloud />;
      // else if (skill.name.toLowerCase() === 'devops')
      //   skillIcon = <SiAzuredevops />;
      // else if (skill.name.toLowerCase() === 'databases')
      //   skillIcon = <AiFillDatabase />;
      return (
        <p className='skill-item'>
          {/* {skillIcon} */}

          <span classname='name'>{skill.name}</span>
          <br />
          <span className='items'>{skill.items.join(', ')}</span>
        </p>
      );
    });

    return (
      <section id='about'>
        <Fade duration={1000}>
          <div className='row'>
            <div className='three columns'>
              <img className='profile-pic' src={profilepic} alt='Profile Pic' />
            </div>
            <div className='nine columns main-col'>
              <h2>About</h2>

              <p>{bio}</p>

              <div className='row'>
                <div className='columns about-items'>
                  <ul>{aboutItems}</ul>
                </div>
              </div>
              <div className='row'>
                <div className='columns'>
                  <h2>Skills</h2>
                  <div className='skills'>{skillItems}</div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
