import React, { Component } from 'react';
import Zmage from 'react-zmage';
import Fade from 'react-reveal';

const Card = (props) => {
  return <div className='card'>{props.children}</div>;
};

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (project) {
      let projectImage = 'images/portfolio/' + project.image;

      const portfolioItem = (
        <div key={id++} className='columns portfolio-item'>
          <div className='item-wrap'>
            <Zmage alt={project.title} src={projectImage} />
            <div style={{ textAlign: 'center' }}>{project.title}</div>
            <div className='description'>{project.description}</div>
          </div>
        </div>
      );
      return <Card>{portfolioItem}</Card>;
    });

    return (
      <section id='portfolio'>
        <Fade left duration={1000} distance='40px'>
          <div className='row'>
            <div className='twelve columns collapsed'>
              <h1>{this.props.data.header}</h1>

              <div id='portfolio-wrapper'>{projects}</div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
