import React, { Component } from 'react';
import Slide from 'react-reveal';

class Experience extends Component {
  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const work = this.props.data.work.map(function (work) {
      const bulletpoints = work.description.map(function (description) {
        return <li>&bull; {description}</li>;
      });
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className='info'>
            {work.title}
            <span>&bull;</span> <em className='date'>{work.years}</em>
          </p>
          <p>
            <ul>{bulletpoints}</ul>
          </p>
        </div>
      );
    });

    return (
      <section id='experience'>
        <Slide left duration={1300}>
          <div className='row work'>
            <div className='three columns header-col'>
              <h1>
                <span>Work</span>
              </h1>
            </div>

            <div className='nine columns main-col'>{work}</div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Experience;
