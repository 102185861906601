import React, { Component } from 'react';
import { Fade } from 'react-reveal';

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const email = this.props.data.email;

    return (
      <section id='contact'>
        <Fade bottom duration={1000}>
          <div className='row section-head'>
            <div className='two columns header-col'>
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className='ten columns'>
              <p className='lead'>
                Get in touch anytime with:{' '}
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Contact;
